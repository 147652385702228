.euiBadge.title--type {
  display: flex;
  justify-content: center;
  border-radius: 15px;
  min-width: 85px;
  min-height: 30px;
  margin: 0.25rem;

  // margin-right: 0.25rem;
  .euiBadge__text {
    color: #fff;
  }
}

.euiBadge.action--close {
  position: relative;
}

.euiBadge.action--close svg {
  margin-top: -10px;
  position: absolute;
  margin-left: 10px;
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.filters--badges {
  margin-left: 5px;
  flex-wrap: wrap;

  .euiBadge {
    margin-right: 4px;
    margin-bottom: 4px;
  }
}