.text-sky-blue label.euiFormRow__label {
  color: var(--blue-sky-color)
}

.euiFormRow[aria-owns="opcional"] {
  color: var(--orange-light);
}

.searchGroup .searhAdvanced--Label {
  color: var(--cyan-color);
  font-size: .6rem;
  margin-top: 5px;
  position: absolute;

  &:hover {
    cursor: pointer;
    color: var(--secondary-color);
  }
}

.searchGroup {
  .euiFieldText.searchInput {
    background-color: transparent;
    color: white;
    box-shadow: none;
  }

  .euiFieldText.searchInput:focus {
    background-color: transparent;
  }
}

.modalDelete {
  width: 20%;
  height: 'auto';
  color: #000;

  h1 {
    color: #FF003D;
  }

  .buttonLeft {
    background-color: #FF003D;
    color: #FFF;
    border: 1px solid #FF003D;
    font-weight: 600;

    &:focus,
    &:hover {
      background-color: #ff426e
    }
  }
}

#modalDetails {
  color: #000;
  height: auto;

  .row {
    display: flex;
    padding: 8px;
    align-items: center;
  }

  b {
    width: 35%;
  }

  .euiFlexGroup {
    flex-flow: wrap;
    justify-content: flex-end;
  }

  .euiFlexGroup .euiBadge.pill.pill--m {
    max-height: 25px;
    min-height: 25px;
    max-width: 70px;
    min-width: 70px;
    margin-bottom: 4px;
  }

  p,
  .euiFlexGroup {
    width: 65%;
    text-align: right;
    overflow-y: hidden;
    overflow-x: hidden;

    a {
      max-width: 50%;
      text-overflow: ellipsis;
    }
  }
}