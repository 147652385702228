.echChart.chart--period {
  border-radius: 3px;
  width: 298px;
  height: 170px;
  background: linear-gradient(109deg, #36d9664a, rgba(54,217,102,.29) 40%, rgba(54,217,102,.29) 45%,  rgba(54,217,102,.29) 50%, 
  rgba(54,217,102,.28) 55%, transparent 100% );
  .euiBadge {
    background-color: transparent !important;
    color: white !important;
    svg {
      margin-right: 5px;
    }
  }
}

