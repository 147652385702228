//* LAYOUT */
.euiOverlayMask {
  background: transparent;
}

.euiFlyout__closeButton--outside {
  display: none;
}

/**Popover */

.euiPanel.euiPopover__panel-isOpen {
  background-color: #fff;
  color: var(--dark-color);
}






.euiHeaderSectionItem--borderRight:after {
  width: 0px;
}

.euiHeaderSectionItem--borderLeft:after {
  width: 0px;
}

.euiButtonEmpty--text {
  color: inherit;
}



/* Combobox */
.euiPanel.euiComboBoxOptionsList {
  background-color: $color-white;
}