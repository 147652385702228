.tableWithActionsVisible {

  .euiTableRow-hasActions .euiTableCellContent--showOnHover .expandedItemActions__completelyHide,
  .euiTableRow-hasActions .euiTableCellContent--showOnHover .expandedItemActions__completelyHide:disabled,
  .euiTableRow-hasActions .euiTableCellContent--showOnHover .expandedItemActions__completelyHide:disabled:hover,
  .euiTableRow-hasActions .euiTableCellContent--showOnHover .expandedItemActions__completelyHide:disabled:focus,
  .euiTableRow:hover .euiTableRow-hasActions .euiTableCellContent--showOnHover .expandedItemActions__completelyHide:disabled {
    opacity: 1;
  }
}

.euiTable {
  background-color: var(--bg-color);
  border-collapse: separate;
  border-spacing: 0 10px;
  font-size: 1em;

  .euiTableRowCell[colspan] {
    >.euiTableCellContent {
      display: block;
    }
  }

  @media (max-width: "1536px") {
    .cellWithRightBorder {
      // min-width: 200px;

    }

    .euiTableRow {
      height: max-content;
      width: max-content;
    }
  }

  .euiButtonIcon.euiButtonIcon--success,
  .euiButton.euiButton--secondary {
    height: 30px;
  }

  .euiTableRow-isExpandedRow .euiTableCellContent {
    overflow: inherit;
  }

  tbody {
    .euiTableRowCell .euiTableCellContent {
      padding: 0;
      min-height: var(--min-h-tableCell);
    }
  }

  table {
    margin-left: 15px;

    .euiTableCellContent {
      padding-left: 0 !important;
    }

    .euiTableHeaderCell .euiTableCellContent {
      min-height: auto;
    }

    .euiTableRow-isExpandedRow .euiTableCellContent {
      overflow: auto;
    }
  }

}

.euiTableRowCell.euiTableRowCell--isExpander {
  padding-right: 5px;
}

.euiTableCellContent.overflow {
  overflow: visible !important;
}

.euiTableFooterCell .euiTableCellContent__text,
.euiTableHeaderCell .euiTableCellContent__text {
  font-size: 1em;
  /* font-weight: bold; */
  text-transform: uppercase;
  letter-spacing: 1px;
}

.euiTableFooterCell,
.euiTableHeaderCell {
  color: #8797ac;
  border-bottom: none;
}

.euiTableRow {
  background-color: var(--table-bg-color);
}

.euiTableRowCell.euiTableRowCell--hasActions {
  padding-right: 20px;
}

.euiTableRow:hover {
  background-color: #041b4f;
}

.euiTableRow:hover .euiTableRowCell {
  color: #8797ac;
}

.euiTableRow.euiTableRow-isExpandedRow {
  background-color: transparent;
}

.euiTableRowCell {
  color: var(--table-color);
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

.euiTableRowCellCheckbox:first-child,
.euiTableRowCell:first-child {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.euiTableRowCell:last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.euiTableRow.euiTableRow-isSelected {
  background-color: #041b4f;
}

.euiTableRow.euiTableRow-isSelected:hover,
.euiTableRow.euiTableRow-isSelected:hover+.euiTableRow.euiTableRow-isExpandedRow .euiTableRowCell {
  background-color: #041b4f;
}

.cellWithRightBorder {
  position: relative;

}

.euiTableCellContent--overflowingContent {
  overflow-x: auto;
}

.cellWithRightBorder::after {
  position: absolute;
  content: '';
  top: 4px;
  bottom: 4px;
  background: rgba(238, 242, 255, 0.2);
  left: 0;

  width: 1px;
  left: auto;
  right: 0;
  margin: 0 12px;
}

/* FOOTER */
[data-test-subj="tablePaginationPopoverButton"] {
  color: white;
}

/**/

/* PAGINATION */
.euiPagination .euiButtonIcon.euiButtonIcon--empty {
  color: #041b4f;
}

.euiPagination .euiPaginationButton-isActive.euiPaginationButton-isActive {
  color: white;
  font-weight: bold;
  text-decoration: none;
}

.euiPagination .euiPaginationButton-isActive.euiPaginationButton-isActive .euiButtonEmpty__text {
  border-bottom: 2px solid var(--success-color);
  border-bottom-width: 2px;
  padding-bottom: 2px;
}

.euiFieldSearch {
  background-color: transparent;
  color: white;
}

.euiFieldSearch:focus {
  background-color: transparent;
}

.euiFilterButton {
  background-color: transparent;
  color: white;
}



.euiTableRowCell .euiButtonEmpty:hover,
.euiTableRowCell .euiButtonEmpty:focus {
  text-decoration: none;
}

.euiBadge.pill {
  border-radius: 15px;

  &.pill--m {
    min-width: 85px;
    min-height: 30px;
  }
}

.euiTableRowCellCheckbox {
  border-top: none;
  border-bottom: none;
}

.euiTableHeaderCellCheckbox {
  border-top: none;
  border-bottom: none;
}

.euiPagination .euiButtonIcon:disabled svg {
  fill: var(--gray-color-2);
}

.euiPagination .euiButtonIcon svg {
  fill: var(--secondary-color);
}


// Customs

.cellWithRightBorder {
  &.Inactive {
    border-left: 2px solid var(--error-color);
  }

  &.undefined {
    border-left: 2px solid var(--table-color);
  }

  &.Active {
    border-left: 2px solid var(--secondary-color);
  }

  &.Redirect {
    border-left: 2px solid var(--orange-light);
  }

  .euiLink.euiLink--primary {
    color: white;
  }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {

  .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandable,
  .euiTable.euiTable--responsive .euiTableRow.euiTableRow-hasActions {
    background-image: linear-gradient(to right, rgba(152, 162, 179, 0.1) 0, rgba(152, 162, 179, 0.1) 1px, transparent 1px, transparent 100%);
    background-size: 40px 100%;
    background-position-x: right;
    background-repeat: no-repeat;
    padding-right: 40px;
    position: relative;
  }
}

@media only screen and (min-width: 337px) and (max-width: 767px) {
  .euiPageBody--paddingLarge {
    padding-left: 70px;
  }

  .euiTable .euiTableRow {
    height: max-content;
    width: auto;
  }

  .euiTable.euiTable--responsive .euiTableRow {
    // box-shadow: 0 2px 2px -1px rgb(152 162 179 / 30%), 0 1px 5px -2px rgb(152 162 179 / 30%);
    background-color: var(--table-bg-color);
    border-radius: 4px;
    border: none;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 8px;
    margin-bottom: 8px;
  }

  .euiTable.euiTable--responsive .euiTableRow:hover {
    background-color: var(--table-bg-color);
  }

  .euiTable.euiTable--responsive .euiTableRowCell {
    display: block;
    min-width: auto;
    border: none;
  }
}

.row-date {
  cursor: pointer;
  color: var(--table-color);

  &.monthly,
  &.weekly,
  &.yearly {
    .euiButtonEmpty__text {
      color: #FF699F
    }

  }

  .euiButtonEmpty__text {
    display: flex;
    align-items: center;
    color: var(--table-color);

    svg {
      margin-right: 5px;
    }

    .icon-infinity {
      margin-top: 4px;
    }
  }
}

.optionsTable {
  padding: 5px;
}