.popover {
  &.list {
    .euiPopoverTitle {
      border-bottom: 0;
      text-transform: capitalize;
      color: var(--cyan-color);
    }
  }

  &.euiPopover__panel.w-middle {
    min-width: 280px;
    max-width: 350px;
  }

  .euiPopoverFooter {
    border: 0;
  }
}


.filters--period.euiFilterGroup {
  box-shadow: none;
  border: 0;

  .euiFilterButton:hover:not(:disabled) .euiFilterButton__textShift,
  .euiFilterButton:focus:not(:disabled) .euiFilterButton__textShift {
    text-decoration: none;
  }

  .euiFilterButton {
    font-size: 12px;
    border: 0;
    max-width: 40px;

    .euiButtonEmpty__text {
      overflow: initial;
    }

    &:hover,
    &-hasActiveFilters {
      background-color: rgba(65, 240, 117, .16);
      border: 1px solid var(--secondary-color);
      border-radius: 5px;
    }

    .euiFilterButton:focus,
    .euiFilterButton:hover:not(:disabled) .euiFilterButton__textShift,
    .euiFilterButton:focus:not(:disabled) .euiFilterButton__textShift {
      text-decoration: none;
    }
  }
}

.popover.default {
  .euiPopoverTitle {
    border-bottom: none;
    text-transform: none;
    color: var(--gray-color-1);
    margin: -16px -16px 0px;
  }

  .euiPopoverFooter {
    justify-content: flex-end;
    margin: 0 -16px -16px;
  }
}

.popover.filter {
  color: var(--dark-color);

  .euiPopoverTitle {
    border: 0;
    text-transform: initial;
    color: var(--gray-color-1);
  }

  .euiFilterButton {
    min-width: 35px;
    width: 35px;
    margin-right: 30px;
    font-size: 12px;
    color: var(--dark-color);
    height: 30px;
    border: 0;
  }
}

.popover.date-picker {
  .euiPopoverFooter {
    padding: 0 !important;
    margin: 0;
  }
}

.popover.chart {
  padding: 3px;
  background-color: var(--dark-color);
  border: 1px solid var(--dark-color);
  z-index: 99 !important;

  .chart-info {
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 0 8px;

    .euiBadge {
      margin-top: 40px;
      margin-right: auto;

      .euiBadge__text,
      .euiBadge__content {
        font-size: 16px;
      }
    }

    span {
      padding: 0;
      font-size: 8px;
      color: #fff;
    }


  }

  .euiPopover__panel.euiPanel--paddingMedium .euiPopoverTitle:not([class*='euiPopoverTitle--padding']) {
    padding: 0;
  }

  .euiPopoverFooter {
    display: flex;
    margin: 0;

    button {
      font-size: 12px;
      margin-left: auto;
      padding: 0;
      height: 30px;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      &:focus {
        background-color: transparent;
      }
    }
  }

  .euiPopoverTitle {
    border: 0;
    display: flex;
    margin: 0;
    justify-content: space-between;
    align-items: center;
    text-transform: initial;
    color: white;
    font-weight: 400;
  }

  .euiFilterButton {
    min-width: 35px;
    width: 35px;
    font-size: 12px;
    color: white;
    height: 25px;
    border: 0;
    margin-left: 5px;
  }
}

.react-datepicker-popper.whithFrequency {
  .react-datepicker__focusTrap {
    display: flex;
    flex-direction: row-reverse;
  }

  .react-datepicker__navigation--next {
    margin-right: 300px;
  }

  .details {
    width: 300px;
  }

}

@media (max-width: 1560px) {
  .euiPopover.render-text {
    max-width: 250px;
  }
}

@media (min-width: 1562px) {
  .euiPopover.render-text {
    max-width: 300px;
  }
}