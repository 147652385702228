.euiButton--primary {
  color: rgb(5, 29, 77);
  border-color: rgb(5, 29, 77);
}

.euiLink.euiLink--text {
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }

}

.euiLink.euiLink--primary:focus {
  background-color: transparent;
}

.euiButton--primary.euiButton--fill {
  background-color: rgb(5, 29, 77);
  border-color: rgb(5, 29, 77);
  color: #FFF;
}

.euiButton--secondary {
  color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.euiLink.euiLink--ghost:focus {
  background-color: transparent;
}

.euiLink.euiLink--subdued {
  color: var(--cyan-color)
}

.euiLink.euiLink--text {

  &:focus {
    background: transparent;
  }

}


.euiButtonIcon--primary {
  color: white;
}

.euiButtonIcon--text {
  color: white;
}

.euiButton {
  text-decoration: none !important;
}


.titleOption .euiButtonEmpty__content {
  padding: 0;
}

.barHorizontal {
  background-color: rgba(238, 242, 255, 0.2);
  height: 20px;
  width: 1px;
}

.bulk--export.ghost a,
.bulk--export.ghost .euiButtonEmpty__text {
  color: #FFF;
}

.bulk--export.text a,
.bulk--export.text .euiButtonEmpty__text {
  color: var(--table-color)
}

.buttonPopoverOptions {
  cursor: pointer;
}