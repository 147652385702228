#sidebar .euiListGroupItem {
  width: 30px;
  height: 50px;
  border-radius: 22px;
}

#sidebar .euiListGroupItem.euiListGroupItem-isClickable:hover {
  background-color: transparent;
}

#sidebar .euiListGroupItem.euiListGroupItem-isClickable {
  .euiIcon--inherit {
    color: var(--gray-color-1);
  }
}

#sidebar .euiListGroupItem.euiListGroupItem-isClickable:hover {
  .euiIcon--inherit {
    color: white;
  }
}

// #sidebar .euiListGroupItem.euiListGroupItem-isClickable:focus-within {
//   background-color: var(--secondary-color);
// }

#sidebar .euiListGroupItem.euiListGroupItem-isActive:hover,
#sidebar .euiListGroupItem.euiListGroupItem-isActive {
  background-color: var(--secondary-color);

  .euiIcon--inherit {
    color: var(--bg-color);
  }
}

#sidebar.euiFlyout {
  width: 70px !important;
}