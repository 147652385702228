/* Flyout */
#sidebar.euiFlyout {
  background-color: $color-primary-dark;
  border: none;
  color: $color-white;
}

@media (min-width: 1536px) {
  .euiFlyout.euiFlyout--small.flyout-rigth {
    min-width: 10vw;
    max-width: 15vw;
    width: 15vw;

  }
}

@media (max-width: 1536px) {
  .euiFlyout.euiFlyout--small.flyout-rigth {
    min-width: 10vw;
    max-width: 20vw;
    width: 20vw;
  }
}

.euiFlyout.euiFlyout--paddingLarge .euiFlyoutFooter {
  padding: 16px 24px;
  display: flex;
  background-color: transparent;
  justify-content: flex-end;
}

.flyout-rigth .filters--period.euiFilterGroup {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.euiFlyout.euiFlyout--paddingLarge .euiFlyoutBody__overflowContent {
  padding: 0;

  .euiFormRow:nth-child(n) {
    border-top: 1px solid var(--gray-color-2);
  }

  .euiFormRow {
    padding: 10px 24px;
  }

}

.euiFlyout.flyout-rigth {
  color: var(--gray-color-1);
  border: none;

  .euiDatePicker {
    background: transparent;
    box-shadow: none;

    .euiFieldText,
    .euiFieldText::placeholder {
      color: var(--cyan-color);
    }

    .euiFieldText:focus {
      background-color: transparent;
      background-image: none;
      background-size: 100% 100%;
      box-shadow: none;
    }
  }

  .euiFlyout__closeButton {
    background-color: transparent;
  }

  .euiFormLabel.euiFormRow__label {
    font-size: 14px;
    font-weight: bold;
  }

  .euiComboBox.search {
    input {
      color: var(--gray-color-2);
    }
  }

  .euiCheckbox__label {
    font-weight: lighter;
  }

  .euiTitle,
  label {
    padding-bottom: 5px;
    color: var(--gray-color-1);
  }

  .euiComboBox .euiComboBox__inputWrap {
    border: 1px solid var(--gray-color-1);
    ;
    border-radius: 4px;

    .euiBadge {
      background-color: transparent;
      color: var(--gray-color-1);
    }
  }

  .euiComboBox.euiComboBox-isOpen,
  .euiComboBox .euiComboBox__inputWrap {
    background-color: var(--bg-dark-color);
  }
}

.euiFlyout.euiFlyout--push.euiFlyout--left {
  border-right: none;
  border-left: none;
}

/* CollapsibleNavGroup */
.euiCollapsibleNavGroup--light {
  background-color: transparent;
}


/* Undocked Menu */
.euiOverlayMask {
  .euiFlyout {
    background-color: $color-primary-dark;
    background: rgb(3, 6, 25);
    background: -moz-linear-gradient(180deg, rgba(3, 6, 25, 1) 0%, rgba(3, 7, 34, 1) 25%, rgba(3, 8, 40, 1) 50%, rgba(3, 7, 34, 1) 75%, rgba(3, 6, 25, 1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(3, 6, 25, 1) 0%, rgba(3, 7, 34, 1) 25%, rgba(3, 8, 40, 1) 50%, rgba(3, 7, 34, 1) 75%, rgba(3, 6, 25, 1) 100%);
    background: linear-gradient(180deg, rgba(3, 6, 25, 1) 0%, rgba(3, 7, 34, 1) 25%, rgba(3, 8, 40, 1) 50%, rgba(3, 7, 34, 1) 75%, rgba(3, 6, 25, 1) 100%);
    border: none;

    color: $color-white;
  }
}