$color-red: #FF003D;
$color-white: #ffffff;
$color-black: #000000;
$color-primary-dark: rgb(3, 6, 25);
$color-primary: rgb(5, 29, 77);

:root {
  --error-color: #ff003d;
  --orange-light: #FF8D69;
  --secondary-color: #41f075;
  --gray-color-1: #8798ad;
  --gray-color-2: #b0bAc9;
  --gray-color-3: #707070;
  --primary-color: #030d2f;
  --bg-color: #030619;
  --bg-dark-color: #242638;
  --table-bg-color: #040d2e;
  --dark-color: #3d4450;
  --white-color: #fff;
  --cyan-color: #5f74a6;
  --blue-sky-color: #00C1D4;
  --table-color: var(--cyan-color);


  --h-header: 80px;
  --min-h-tableCell: 77px;
}

/* * {
  margin: 0;
  padding: 0;
} */
html,
body {
  height: 100%;
  background: var(--bg-color);
}

body {
  color: white;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // background: -moz-linear-gradient(180deg, rgba(3,6,25,1) 0%, rgba(3,7,34,1) 25%, rgba(3,8,40,1) 50%, rgba(3,7,34,1) 75%, rgba(3,6,25,1) 100%);
  // background: -webkit-linear-gradient(180deg, rgba(3,6,25,1) 0%, rgba(3,7,34,1) 25%, rgba(3,8,40,1) 50%, rgba(3,7,34,1) 75%, rgba(3,6,25,1) 100%);
  // background: linear-gradient(180deg, rgba(3,6,25,1) 0%, rgba(3,7,34,1) 25%, rgba(3,8,40,1) 50%, rgba(3,7,34,1) 75%, rgba(3,6,25,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#030619", endColorstr="#030619", GradientType=1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import "~@elastic/eui/dist/eui_theme_light.css";

/**/
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
  background: #030722;
}

::-webkit-scrollbar-thumb {
  background: #030722;
  border: 0px none transparent;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
  background: #030828;
}

::-webkit-scrollbar-thumb:active {
  background: #fff;
}

::-webkit-scrollbar-track {
  background: #051d4d;
  border: 0px none transparent;
  border-radius: 100px;
}

::-webkit-scrollbar-track:hover {
  background: #1d5c9f;
}

::-webkit-scrollbar-track:active {
  background: #030619;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

/* UTILS */

.text--success {
  color: var(--secondary-color) !important;
}

svg.text--success {
  fill: var(--secondary-color) !important;
}

.text--red {
  color: $color-red !important;
}

.text--white {
  color: $color-white !important;
}

.text--black {
  color: $color-white !important;
}

.width-full {
  width: 100%;
}

.text--left {
  text-align: left;
}

.text--center {
  text-align: center;
}

.text--right {
  text-align: right;
}

.position-relative {
  position: relative;
}

.display-flex {
  display: flex;
}

.align-center {
  align-items: center;
}


.justify-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-start {
  justify-content: flex-start;
}

.w-100 {
  width: 100% !important;
}

.w-20 {
  width: 20% !important;
}

.m-x-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.m-x-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.m-x-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.m-x-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.m-y-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.m-y-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.m-l-30px {
  margin-left: 30px !important;
}

.m-y-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.m-y-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.m-r-1 {
  margin-right: 0.25rem;
}

.m-r-2 {
  margin-right: 0.5rem;
}

.m-r-3 {
  margin-right: 0.75rem;
}

.m-r-4 {
  margin-right: 1rem;
}

.m-t-1 {
  margin-top: 0.25rem;
}

.m-t-2 {
  margin-top: 0.5rem;
}

.m-t-3 {
  margin-top: 0.75rem;
}

.m-t-4 {
  margin-top: 1rem;
}

.m-b-1 {
  margin-bottom: 0.25rem;
}

.m-b-2 {
  margin-bottom: 0.5rem;
}

.m-b-3 {
  margin-bottom: 0.75rem;
}

.m-b-4 {
  margin-bottom: 1rem;
}

.p-x-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.p-x-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-x-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.p-x-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.p-15 {
  padding: 15px !important;
}

.p-30 {
  padding: 30px !important;
}


.page {
  padding: 1rem;
}

.bg-dark {
  background-color: var(--bg-dark-color) !important;
}

.page [class^="page"] {
  padding-top: 1rem;
}