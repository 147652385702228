
.circle {
  border: 1px solid;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    stroke: var(--gray-color-1);
    &:hover {
      stroke: white;
    }
  }
}
.circle.p {
  height: 33px;
  width: 33px;
  padding: 8px;
  svg.plus {
    position: absolute;
    margin-top: 28px;
    margin-left: 20px;
    height: 12px;
    fill: var(--cyan-color);
  }
}
.circle.dashed {
  border: 1px dashed var(--gray-color-3);
}