.euiPage {
  padding-top: var(--h-header);
  background-color: var(--bg-color);
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.euiPage.isFixed thead,
.euiPage.isFixed .actionsTable--bar {
  position: sticky;
  background-color: var(--bg-color);
  z-index: 1;
}

.euiPage.isFixed tbody thead {
  z-index: 0;
}

.euiPage.isFixed thead {
  top: var(--h-header);
}

.euiPage.isFixed .actionsTable--bar {
  top: 0;
}

.euiHeader {
  background-color: var(--bg-color);
  border-bottom: none;
  height: var(--h-header);
  box-shadow: 0 2px 0 #58546b;
}

.euiPageContent.euiPageContent--horizontalCenter {
  margin-left: 0;
  margin-right: 0;
}

.status.Inactive {
  color: var(--error-color);
}

.status.Active {
  color: var(--secondary-color);
}

.status.Redirect {
  color: var(--orange-light);
}

.euiBreadcrumbs__list li {
  font-size: 1.5rem;
}

.euiTitle-page {
  color: var(--cyan-color);
  padding: 30px 0;

  &.euiBreadcrumbs {

    a:hover,
    .euiLink.euiLink--subdued:focus {
      color: var(--secondary-color);
      text-decoration: none;
      background-color: transparent;
    }

    a {
      color: white;
      font-weight: 400;
    }

    span {
      font-weight: 400;
      color: var(--secondary-color);
    }
  }
}

@media (max-width: 1640px) {
  .euiBasicTable {
    max-width: 99%;
  }
}

#home-page {
  .euiTitle {
    color: white;
  }

  .euiTab.euiTab-isSelected {
    text-decoration: none;

    .euiTab__content {
      color: white;
    }
  }

  .euiTab {
    .euiTab__content {
      color: var(--gray-color-1);
      font-weight: 600;
    }
  }

  .euiTab:focus {
    background-color: transparent;
  }

  .euiTab::after,
  .euiTab::before {
    height: 2px;
    background-color: var(--secondary-color);
  }

  .euiTab:hover {
    .euiTab__content {
      color: white;
    }
  }

  .logs-Title {
    padding: 20px 0;
  }

  .logs {
    .euiFlexItem {
      flex-direction: row;
      color: var(--gray-color-2);

      span {
        color: var(--cyan-color);
        text-decoration: underline;
        padding: 0 .25rem;
      }
    }
  }


}

.euiBasicTable {
  max-width: 99%;
}

.euiTable--page {

  .euiCheckbox .euiCheckbox__input+.euiCheckbox__square,
  .euiCheckbox .euiCheckbox__input:checked+.euiCheckbox__square {
    border-color: var(--gray-color-2);
    background-color: var(--bg-color);
  }

  .euiBasicTable {
    .euiDatePicker.euiFieldText {
      max-width: 130px;
    }

    .euiButtonEmpty.euiButtonEmpty--text,
    .euiLink.euiLink--text {
      color: var(--cyan-color);
      padding: 10px 0;

      &:hover {
        text-decoration: none;
        color: white;
      }
    }



  }

  .euiTableRowCell:first-child {
    color: white;
    // font-weight: 600;

  }
}

.search--icon {
  margin-left: -35px;
  z-index: 1;
}

.euiFieldText.search {
  display: block;
  z-index: 0;
  -webkit-animation: swing 1s ease;
  animation: swing 1s ease;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.euiTable--page .euiTableRowCellCheckbox,
.euiTable--page.euiPanel.euiPanel--plain {
  background-color: var(--bg-color);
}

.actionsTable--bar {
  padding: 15px 0;
}

.euiTable-actions {
  overflow: visible !important;

  .bulk--selecteds {

    &.active {
      border-bottom: 1px solid white;
    }

    &:hover {
      text-decoration: none;
    }
  }

  overflow: overlay;

  .euiButton.euiButton--secondary {
    height: 22px;
    font-size: 10px;
    width: auto;
    min-width: 20px;
  }

  button:focus {
    background-color: transparent;
  }

  align-items: center;

  .euiButtonEmpty__text {
    display: flex;
    align-items: center;

    svg {
      margin-left: 5px;
    }
  }

  svg {
    fill: var(--gray-color-2);
  }

  svg:hover {
    fill: var(--white-color);
  }

  .euiBadge {
    background-color: transparent !important;
    color: var(--secondary-color) !important;
    border: 1px solid var(--secondary-color) !important;
    height: 22px;

    button svg {
      fill: var(--secondary-color);
    }
  }
}

.bulk--actions {
  align-items: center;

  button {
    border: 0;
  }

  button.euiLink.euiLink--text {
    color: #fff;

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      background: transparent;
    }
  }
}